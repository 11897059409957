import { gsap, Linear } from 'gsap';
import { ScrollScene, ScrollMagic } from 'scrollscene';

const bind = () => {
  const controller = new ScrollMagic.Controller();

  const parents = document.querySelectorAll('[data-parallax-parent]');
  parents.forEach((parent) => {
    const children = parent.querySelectorAll('[data-parallax-child]');
    children.forEach((child) => {
      const duration = child.dataset.parallaxDuration ? child.dataset.parallaxDuration : '100%';
      const offset = child.dataset.parallaxOffset ? child.dataset.parallaxOffset : '-100px';
      const length = child.dataset.parallaxLength ? child.dataset.parallaxLength : '20%';

      const tl = gsap.timeline();
      tl.from(child, {
        y: length,
        ease: Linear.easeNone,
      });

      const scene = new ScrollScene({
        triggerElement: parent,
        triggerHook: 'onCenter',
        duration,
        offset,
        gsap: {
          timeline: tl,
        },
      });
      scene.Scene
        // .addIndicators()
        .addTo(controller);
    });
  });

  const functionality = document.querySelectorAll('[data-functionality-parent]');
  functionality.forEach((wrapper) => {
    const children = wrapper.querySelectorAll('[data-functionality-parent]');
    const image = wrapper.querySelector('[data-image-child]');
    const imageDuration = image.dataset.parallaxDuration ? image.dataset.parallaxDuration : '100%';
    const imageOffset = image.dataset.parallaxOffset ? image.dataset.parallaxOffset : '-150px';
    const imageLength = image.dataset.parallaxLength ? image.dataset.parallaxLength : '20%';
    children.forEach((child) => {
      const duration = child.dataset.parallaxDuration ? child.dataset.parallaxDuration : '100%';
      const offset = child.dataset.parallaxOffset ? child.dataset.parallaxOffset : '-150px';
      const length = child.dataset.parallaxLength ? child.dataset.parallaxLength : '20%';

      const tl = gsap.timeline();
      tl.from(child, {
        y: length,
        ease: Linear.easeNone,
      });
      const scene = new ScrollScene({
        triggerElement: wrapper,
        triggerHook: 'onCenter',
        duration,
        offset,
        gsap: {
          timeline: tl,
        },
      });
      scene.Scene
        // .addIndicators()
        .addTo(controller);

      const time = gsap.timeline();
      time.from(image, {
        y: imageLength,
        ease: Linear.easeNone,
      });
      const secondScene = new ScrollScene({
        triggerElement: wrapper,
        triggerHook: 'onCenter',
        duration: imageDuration,
        offset: imageOffset,
        gsap: {
          timeline: time,
        },
      });
      secondScene.Scene
        // .addIndicators()
        .addTo(controller);
    });
  });
};

document.addEventListener('DOMContentLoaded', bind);
document.addEventListener('DOMContentMutated', bind);