function initHamburgers() {
  const mobileHamburger = document.querySelector('[data-mobile-hamburger]');
  if (mobileHamburger) {
    mobileHamburger.addEventListener('click', (e) => {
      const menu = document.querySelector('[data-expand-block');
      if (!menu.classList.contains('_opened')) {
        menu.classList.add('_opened');
        mobileHamburger.classList.add('_opened');
      } else {
        menu.classList.remove('_opened');
        mobileHamburger.classList.remove('_opened');
      }
    });
  }
}
document.addEventListener('DOMContentLoaded', initHamburgers);